@font-face {
  font-family: "Doulos";
  font-display: swap;
  src: local('Doulos SIL'),
  url('../fonts/DoulosSILR.ttf') format('truetype'),
}

.phonetic-symbol{
    font-family: Doulos;
    font-size: 16px;
}

