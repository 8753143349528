/* Force selection of entire .katex/.katex-display blocks, so that we can
 * copy/paste the entire source code.  If you omit this CSS, partial
 * selections of a formula will work, but will copy the ugly HTML
 * representation instead of the LaTeX source code.  (Full selections will
 * still produce the LaTeX source code.)
 */
.katex,
.katex-display {
    user-select: all;
    -moz-user-select: all;
    -webkit-user-select: all;
    -ms-user-select: all;
}
