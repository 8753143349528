// @import "./resources/styles/normalize.css";
@import "./resources/styles/doulos.scss";
@import "./resources/styles/syncfusion.scss";
@import "./resources/styles/fontawesome.scss";
@import "./resources/styles/primeflex.scss";
@import "./resources/styles/primeicons.scss";
@import "./resources/styles/primeng.scss";
@import "katex/dist/katex.css";
@import "./resources/styles/copy-tex.css";
@import "./resources/styles/ultima-override.scss";
@import "./resources/styles/solidmemory.scss";
