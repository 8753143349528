.p-input-filled .p-inputtext:enabled:hover {
  background-color: #ffffff;
  border-color: transparent;
  background-image: linear-gradient(to bottom, #3f51b5, #3f51b5),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.87), rgba(0, 0, 0, 0.87));
}

.p-input-filled .p-inputtext {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: #ffffff no-repeat;
  background-image: linear-gradient(to bottom, #3f51b5, #3f51b5),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.38));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box;
}

.p-inputtext,
.p-calendar-w-btn,
.p-dropdown {
  background-color: transparent !important;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  display: none !important;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background-color: #2196f3;
}

.layout-wrapper .layout-main .layout-content {
  padding: 1rem;
  padding-top: 0rem;
}

button:not([type="button"]) > span {
  display: none !important;
}

.p-togglebutton.p-button.p-highlight {
  background-color: #8bc34a;
  color: #fff;
}

.p-autocomplete-dd .p-autocomplete-dropdown {
  background-color: transparent !important;
  color: black !important;
}

.p-fieldset .p-fieldset-legend {
  background: transparent;
}

.p-fluid .p-fileupload .p-button {
  width: 100% !important;
}

.layout-menu-light .menu-wrapper {
  box-shadow: none;
}

.menu-wrapper .layout-menu-container .layout-menu li > ul > li {
  margin-bottom: 0.2rem;
}

.menu-wrapper .layout-menu-container .layout-menu li > a {
  padding: 0.5rem;
}

app-menu {
  position: relative;
  top: -0.5rem;
}

// .p-dialog .p-dialog-content {
//   background: #ffffff;
//   color: rgba(0, 0, 0, 0.87);
//   padding: 0.5rem;
// }

.p-dialog {
  box-shadow: none;
}

.p-toast-top-right {
  top: 2rem !important;
}

@media (max-width: 330px) {
  .p-toast-top-right {
    right: 5 !important;
  }
  .p-toast {
    width: 300px !important;
  }
}

@media (min-width: 331px) and (max-width: 340px) {
  .p-toast-top-right {
    right: 10px !important;
  }
  .p-toast {
    width: 300px !important;
  }
}

@media (min-width: 341px) {
  .p-toast-top-right {
    right: 10px !important;
  }
  .p-toast {
    width: 310px !important;
  }
}

.layout-menu-light .menu-wrapper .layout-menu-container .layout-menu li a {
  text-decoration: none;
}
