html, body {
  overscroll-behavior: none;
}

h1.page-title {
  font-size: 1.5em;
  font-weight: bold;
  margin: 1em 0.5em 0.5em 0.5em;
}

h1.slogan {
  font-size: 1.2em;
  font-weight: bold;
  margin: 1em 0.5em 0.5em 0.5em;
}


.page-subtitle {
  font-size: 1.25em;
  font-weight: bold;
}

.centered-title {
  font-size: 1.25em;
  font-weight: bold;
  text-align: center;
}

.thumbnail-band {
  margin: 7px;
}

.thumbnail {
  margin: 1px;
}

.text-align-center {
  text-align: center;
}

.offScreen {
  position: absolute;
  top: -5000px;
  left: -5000px;
}

.answer-input {
  font-size: 16pt;
  letter-spacing: 1px;
  font-family: "Lucida Console", Monaco, monospace;
}

.text-uppercase {
  text-transform: uppercase;
}

.expired {
  color: red;
}

.highlighted-text {
  color: #d00053;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.has-stroke-character {
  border: 1px solid #5bc0de;
  border-radius: 5px;
  padding: 2px 4px;
}

.tiny-black-border {
  border: 1px solid black;
}

.small-margin {
  margin: 5px;
}

.medium-margin {
  margin: 10px;
}

.medium-margin-top {
  margin-top: 10px;
}

.large-margin {
  margin: 20px;
}

.large-padding-left {
  padding-left: 20px;
}

.nopad {
  padding: 0em;
}

.no-horizontal-pad {
  padding-left: 0;
  padding-right: 0;
}

.hide-topbar {
  position: relative;
  top: -4rem;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2000;
  transform: translate(-50%, -50%);
}

.upgrade-button {
  background-color: #dea201;
  color: while;
  height: 15px;
}

.onboarding-image {
  max-width: 300px;
  display: inline-block;
}

.onboarding-image-container {
  text-align: center;
}

.vertical-align-sub {
  vertical-align: sub;
}

.faq-table td {
  padding: 0 15px;
  text-align: center;
}

.test-table {
  border: 0.5px solid black;
  border-collapse: collapse;
  padding: 2px 5px;
  font-size: small;
}

.word-lookup {
  color: #2196f3;
  text-decoration: underline;
  cursor: zoom-in;
}

.p-autocomplete {
  display: inline-block;
  width: 100%;
}

.p-autocomplete-input {
  display: inline-block;
  width: 100%;
}

.p-button {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 100%;
}

input {
  width: 100%;
}

.p-inputtextarea {
  width: 100%;
}

.p-button-action {
  background-color: #dea201;
  color: white;
}

.participation-graph {
  max-width: 1024px;
}

.odometer.odometer-theme-plaza .odometer-digit {
  color: white!important;
}

.odometer.odometer-theme-plaza {
  background-color: #52b700!important;
}

.odometer-formatting-mark {
  color: white!important
}

.axis {
  font-size: 1em;
  shape-rendering: geometricPrecision;
}

.p-progressbar .p-progressbar-value {
  background: #2196f3;
  border-radius: 3px;
}
